import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dataset from 'airborne/bookings2/helpers/dataset';
import {selectPages} from 'airborne/search2/helpers/pages';


export default class Pager extends React.Component {
    static propTypes = {
        'start_index': PropTypes.number.isRequired,
        'end_index': PropTypes.number.isRequired,
        'total': PropTypes.number.isRequired,
        'page': PropTypes.number.isRequired,
        'total_pages': PropTypes.number.isRequired,
        'onChange': PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        event.preventDefault();

        const {page} = dataset(event.target);
        if (page) {
            this.props.onChange(Number(page));
        }
    }

    renderPage(page, label, active, idx) {
        const className = classnames({'sr-pagination--active': active});
        return (
                <li key={[idx, label, page]} className={className}>
                {page ? (<a href="#" data-page={page}>{label}</a>)
                      : label}
            </li>
        );
    }

    renderPages(current, total) {
        if (total === 1) {
            return null;
        }
        const pages = selectPages(current, total);

        return (
            <ul className="sr-pagination__pages" onClick={this.handleChange}>
                {pages.map(({page, label}, idx)=> (
                    this.renderPage(page, label, page === current, idx)
                ))}
            </ul>
        );
    }

    renderCounter(start, end, total) {
        const text = gettext(
            '{start} – {end} of {count} hotels',
            {start, end, count: total}
        );
        return (
            <span className="sr-pagination__counter">
                {text}
            </span>
        );
    }

    render() {
        const {
            total,
            start_index: start,
            end_index: end,
            page,
            total_pages: totalPages
        } = this.props;
        if (!total) {
            return null;
        }

        return (
            <div className="sr-pagination">
                {this.renderPages(page, totalPages)}
                {this.renderCounter(start, end, total)}
            </div>
        );
    }
}
