import classnames from 'classnames';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import gettext from 'airborne/gettext';
import Highlight from 'airborne/homepage2/Highlight';
import {hotelsTestIds} from 'airborne/hotels/testIds';
import BookButton from 'airborne/search2/MultiBookButton';
import {copyHotelOfferInfo} from 'airborne/search2/helpers/copyToClipboard';
import {rateLabeling, isTopRatesEnabled} from 'airborne/search2/helpers/hyatt';
import limitText from 'airborne/search2/helpers/limitText';
import normalize from 'airborne/search2/helpers/normalize';
import {
    getHotelRates,
    getHotelRatesLoading,
    getHotelRatesWarnings,
    getHotelRatesSelection,
    getRateFilterById,
    displayRoomType,
    displayBedTypes,
    DEFAULT_PAGINATED,
} from 'airborne/search2/helpers/rates';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';
import CopyToClipboardTooltip from 'airborne/search2/hotel/CopyToClipboardTooltip';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {unselectHotel} from 'airborne/store/modules/search_hotels/actions/filters';
import {
    getRates,
    setRateOrder,
    expandRates,
    selectRate,
    unselectRate,
    filterRates,
} from 'airborne/store/modules/search_hotels/actions/rates';
import AmenitiesIcons from 'midoffice/components/AmenitiesIcons';
import Button from 'midoffice/components/Button';
import Glyphicons from 'midoffice/components/Glyphicons';

import CancelInfo from './CancelInfo';
import Commissionable from './Commissionable';
import EmailOffer from './EmailOfferButton';
import HotelTab, {HotelTabHeader} from './HotelTab';
import NoRates from './NoRates';
import OutOfPolicy from './OutOfPolicy';
import PaidPolicy from './PaidPolicy';
import PaymentIcons from './PaymentIcons';
import Price from './Price';
import ProviderLabel from './ProviderLabel';
import RateCode from './RateCode';
import RateDetails from './RateDetails';
import RateFiltersMenu from './RateFiltersMenu';
import RateFiltersTags from './RateFiltersTags';
import RatePernightTooltip from './RatePernightTooltip';
import RateSorters from './RateSorters';
import TopRates, {getMarkOfInterestLabel} from './TopRates';

class TabLabel extends React.Component {
    static propTypes = {
        total: PropTypes.number,
    };

    render() {
        const {total} = this.props;
        return (
            <span>
                <strong>{gettext('Rates')}</strong>
                &nbsp;
                {total ? `(${total})` : null}
            </span>
        );
    }
}

export class ClientPreferredLabel extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="h-rate__code">
                <p className="h-rate__options h-rate__options--green text-xs">{gettext('Client Rate')}</p>
            </div>
        );
    }
}

function OnRequestLabel() {
    return (
        <AnnotateTooltip id="on-request">
            <p className="h-rate__options h-rate__options--orange text-xs">
                <strong>
                    {gettext('On-request rate')} <Glyphicons bsClass="glyphicons" glyph="circle-info" />
                </strong>
            </p>
            {gettext('On-request rates confirmation will not be immediate and may take up to 24 hours.')}
        </AnnotateTooltip>
    );
}

function getLabels(isOnRequest) {
    return isOnRequest
        ? {
              single: gettext('Request'),
              multi: gettext('Request For'),
              remaining: gettext('Request this rate for all travelers »'),
          }
        : {
              single: gettext('Book'),
              multi: gettext('Book For'),
              remaining: gettext('Book this rate for all travelers »'),
          };
}

export class SimilarRates extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        providerIds: PropTypes.object,
        rate: PropTypes.object,
        labeling: PropTypes.shape({
            supplier: PropTypes.bool,
            agencyNegotiated: PropTypes.bool,
            clientNegotiated: PropTypes.bool,
            outOfPolicy: PropTypes.bool,
        }),
        onSelect: PropTypes.func.isRequired,
        onWrongButton: PropTypes.func.isRequired,
        order: PropTypes.string,
        rateFilter: PropTypes.array,
        searchPosition: PropTypes.number,
        hotel: PropTypes.object,
    };
    state = {
        show: false,
    };
    getSnapshotBeforeUpdate(prevProps) {
        const {rateFilter, order} = this.props;
        const isTheSameFilter = rateFilter && prevProps.rateFilter.toString() !== rateFilter.toString();
        const isTheSameOrder = order && prevProps.order !== order;
        if (isTheSameOrder || isTheSameFilter) this.setState({show: false});
    }
    toggleShowBtn = () => {
        this.setState({show: !this.state.show});
    };
    render() {
        const {id, labeling, rate, providerIds, searchPosition, hotel} = this.props;
        const {show} = this.state;
        const similarRatesLength = show && rate.children.length > 0 ? rate.children.length : null;
        return (
            <>
                {show && rate.children.length > 0 && (
                    <div className="h-rate__group-section">
                        <header className="h-rate__group-section__header">
                            <h3 className="h-rate__group-section__title">
                                <strong>{gettext('Similar rates:')} </strong>
                            </h3>

                            <Button bsSize="sm" className="btn-link" onClick={this.toggleShowBtn}>
                                <Glyphicons bsClass="glyphicon" glyph="minus-sign" /> {gettext('Hide similar rates')}
                            </Button>
                        </header>

                        {rate.children.map((rate, index) => (
                            <Rate
                                key={rate.rateKey}
                                searchPosition={searchPosition}
                                hotelId={id}
                                providerId={providerIds && providerIds[rate.provider]}
                                labeling={labeling}
                                ratesLength={similarRatesLength}
                                ratePosition={index + 1}
                                {...rate}
                                onSelect={this.props.onSelect}
                                onWrongButton={this.props.onWrongButton}
                                originalRate={rate}
                                hotel={hotel}
                            />
                        ))}
                    </div>
                )}

                {rate.children.length > 0 && (
                    <div className="h-rate__group-section__trigger">
                        <Button className="btn-link" onClick={this.toggleShowBtn}>
                            {show ? (
                                <>
                                    <Glyphicons bsClass="glyphicon" glyph="minus-sign" />{' '}
                                    {gettext('Hide similar rates')}
                                </>
                            ) : (
                                <>
                                    <Glyphicons bsClass="glyphicon" glyph="plus-sign" /> {gettext('Show similar rates')}
                                </>
                            )}
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

function RoomLabels({remainingRoomsNumber, roomType}) {
    const remainingLabel = remainingRoomsNumber && gettext('{num} left', {num: remainingRoomsNumber});
    const labels = [displayRoomType(roomType), remainingLabel].filter(item => item);

    return (
        <div className="col">
            <strong>{gettext('Room:')}</strong> {labels.join(' • ')}
        </div>
    );
}

RoomLabels.propTypes = {
    remainingRoomsNumber: PropTypes.number,
    roomType: PropTypes.string,
};

export class Rate extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        providerId: PropTypes.any,
        cancelBy: PropTypes.string,
        cancellationPolicy: PropTypes.string,
        cancellationPolicyKnown: PropTypes.bool,
        changeDuringStay: PropTypes.bool,
        commission: PropTypes.object,
        currency: PropTypes.string.isRequired,
        nonRefundable: PropTypes.bool,
        provider: PropTypes.string.isRequired,
        paidPolicy: PropTypes.string.isRequired,
        paymentOptions: PropTypes.array.isRequired,
        cvvRequired: PropTypes.bool.isRequired,
        rateCode: PropTypes.string,
        rateCompanyName: PropTypes.string,
        roomTypeCode: PropTypes.string,
        remainingRoomsNumber: PropTypes.number,
        rateValueAdds: PropTypes.array.isRequired,
        clientPreferred: PropTypes.bool,
        membersOnly: PropTypes.bool,
        isBcdGreatRate: PropTypes.bool,
        isOnRequest: PropTypes.bool,
        tmcPreferred: PropTypes.bool,
        outOfPolicy: PropTypes.bool,
        outOfPolicyReason: PropTypes.object,
        labeling: PropTypes.shape({
            supplier: PropTypes.bool,
            agencyNegotiated: PropTypes.bool,
            clientNegotiated: PropTypes.bool,
            outOfPolicy: PropTypes.bool,
        }),
        rateKey: PropTypes.string.isRequired,
        ratePromoline: PropTypes.string,
        rateDescription: PropTypes.string.isRequired,
        average: PropTypes.number.isRequired,
        onSelect: PropTypes.func.isRequired,
        bedTypes: PropTypes.array,
        loyaltyCardAllowed: PropTypes.bool,
        searchPosition: PropTypes.number,
        ratesLength: PropTypes.number,
        ratePosition: PropTypes.number,
        roomType: PropTypes.string,
        onWrongButton: PropTypes.func.isRequired,
        clientPreferredByChain: PropTypes.bool,
        hotel: PropTypes.object,
        originalRate: PropTypes.object,
        isDiscounted: PropTypes.bool,
        isCorporate: PropTypes.bool,
    };

    handleDetails = event => {
        event && event.preventDefault();
        const {hotelId, rateKey} = this.props;
        this.props.onSelect(hotelId, rateKey);
    };

    handleWrongButtonClick = () => {
        const {hotelId, rateKey, onWrongButton} = this.props;
        onWrongButton(hotelId, rateKey);
    };

    handleCopyToClipboard = () => {
        const {originalRate, hotel} = this.props;
        copyHotelOfferInfo(hotel, originalRate);
    };

    render() {
        const {
            hotelId,
            provider,
            providerId,
            commission: {commissionable} = {},
            changeDuringStay,
            nonRefundable,
            outOfPolicy,
            outOfPolicyReason,
            paidPolicy,
            paymentOptions = [],
            rateKey,
            rateCode,
            rateCompanyName,
            ratePromoline,
            rateDescription,
            roomTypeCode,
            rateValueAdds,
            cancelBy,
            cancellationPolicy,
            cancellationPolicyKnown,
            labeling,
            clientPreferred,
            membersOnly,
            isDiscounted,
            isCorporate,
            isBcdGreatRate,
            tmcPreferred,
            average,
            currency,
            cvvRequired,
            bedTypes,
            loyaltyCardAllowed,
            isOnRequest,
            remainingRoomsNumber,
            searchPosition,
            ratesLength,
            ratePosition,
            roomType,
        } = this.props;

        const LABELS = getLabels(isOnRequest);

        const rateOfInterestLabel = getMarkOfInterestLabel({membersOnly, isDiscounted, isCorporate}, true);

        return (
            <div className="h-rate" data-testid={'hotels-rate'}>
                <div className="h-rate__tag">
                    <div className="h-rate__hover">
                        <CopyToClipboardTooltip onClick={this.handleCopyToClipboard} />
                    </div>
                    {/* DES-487 Placeholder: Smart Choice rate highlighting
                    GG-24924 No need for gettetxt
                */}
                    {/*
                <span className="h-rate__label h-rate__label--green">
                    <Glyphicon glyph="star" />
                    {'Smart Choice'}
                </span>
                */}
                    {rateOfInterestLabel.length > 0 && <span className="h-rate__label">{rateOfInterestLabel}</span>}
                    {isBcdGreatRate && (
                        <span className="h-rate__label h-rate__label--orange">{gettext('Great Rate')}</span>
                    )}
                </div>
                <div className="h-rate__content">
                    <div className="h-rate__col h-rate__col--price">
                        <Price className="h-rate__price" available min={average} currency={currency}>
                            {changeDuringStay && <RatePernightTooltip hotelId={hotelId} rateKey={rateKey} />}
                        </Price>
                        <RateCode
                            provider={provider}
                            rate={rateCompanyName || rateCode}
                            room={roomTypeCode}
                            client={clientPreferred && labeling.clientNegotiated}
                            tmc={tmcPreferred && labeling.agencyNegotiated}
                            membersOnly={membersOnly}
                        />

                        <ClientPreferredLabel show={clientPreferred} />
                    </div>
                    <div className="h-rate__col h-rate__col--description">
                        <div className="h-rate__description__details">
                            <RoomLabels remainingRoomsNumber={remainingRoomsNumber} roomType={roomType} />
                            <div className="col">
                                <strong>{gettext('Bed:')}</strong> {displayBedTypes(bedTypes)}
                            </div>

                            <div className="col">
                                <Button
                                    className="btn-link btn-link--narrow pull-right"
                                    bsStyle="link"
                                    bsSize="sm"
                                    onClick={this.handleWrongButtonClick}
                                >
                                    {gettext('Improve')}
                                </Button>
                            </div>
                        </div>

                        <h4 className="h-rate__name">{limitText(ratePromoline, 90)}</h4>
                        <p className="h-rate__description">{limitText(rateDescription, 150).toLowerCase()}</p>

                        <div className="h-rate__label-bar">
                            {isOnRequest && <OnRequestLabel />}

                            {loyaltyCardAllowed && (
                                <p className="h-rate__options h-rate__options--green text-xs">
                                    {gettext('Loyalty point eligible')}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="h-rate__col h-rate__col--details">
                        <div className="h-rate__details">
                            <PaidPolicy hotelId={hotelId} rateKey={rateKey} paidPolicy={paidPolicy} />

                            <div>
                                <Commissionable value={commissionable} />
                            </div>
                        </div>

                        <div className="h-rate__cxl">
                            <strong>{gettext('Cxl By')}:</strong>
                            <CancelInfo
                                hotelId={hotelId}
                                rateKey={rateKey}
                                nonRefundable={nonRefundable}
                                cancelBy={cancelBy}
                                cancelPolicy={cancellationPolicy}
                                cancelPolicyKnown={cancellationPolicyKnown}
                            />
                        </div>
                    </div>

                    <div className="h-rate__col h-rate__col--control">
                        <BookButton
                            hotelId={hotelId}
                            rateKey={rateKey}
                            variant={isOnRequest ? 'warning' : 'info'}
                            className="pull-right"
                            multiBookLabel={LABELS.multi}
                            remainingBookLabel={LABELS.remaining}
                            data-non-sc={true}
                            data-book-button={true}
                            data-sc-best-loyalty={false}
                            data-sc-best-rate={false}
                            data-sc-best-non-loyalty={false}
                            data-sc-cheapest={false}
                            data-hotel-position={searchPosition}
                            data-rate-position={ratePosition}
                            data-number-of-rates={ratesLength}
                        >
                            {LABELS.single}
                        </BookButton>
                        <div className="h-rate__note">
                            <OutOfPolicy
                                show={outOfPolicy && labeling.outOfPolicy}
                                reason={outOfPolicyReason}
                                currency={currency}
                            />
                        </div>
                    </div>
                </div>
                <footer className="h-rate__footer">
                    <div className="h-rate__col h-rate__col--amenities">
                        <AmenitiesIcons withWrapper codes={rateValueAdds} />
                    </div>

                    <div className="h-rate__col h-rate__col--general">
                        {labeling.supplier && (
                            <div className="rev-provider-ico">
                                <ProviderLabel id={providerId} show={labeling.supplier} provider={provider} />
                            </div>
                        )}
                        <Button
                            data-testid={hotelsTestIds.results.hotelCardRateDetailsButton}
                            bsStyle="link"
                            className="btn-link--narrow h-rate__details-link"
                            onClick={this.handleDetails}
                        >
                            <strong>{gettext('Rate Details')} »</strong>
                        </Button>

                        <EmailOffer hotelId={hotelId} rateKey={rateKey} />
                    </div>

                    <div className="h-rate__col h-rate__col--misc">
                        <div className="h-rate__payments">
                            <div className="h-rate__payments__title">{gettext('Accepted Payments')}:</div>
                            <PaymentIcons paymentOptions={paymentOptions} cvv={cvvRequired} />
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

const hotelSelector = createSelector([({hotels: {hotels}}) => hotels.data, (state, {id}) => id], (hotels, id) =>
    find(hotels, {id})
);

function sortRates(a, b) {
    return a.sortMetric - b.sortMetric;
}
function normalizeRates(fullRatesList, paginatedRates, isRateGrouping) {
    const rates = paginatedRates.rates.map(rateKey => find(fullRatesList, {'rate_key': rateKey})).map(normalize);
    if (!isRateGrouping || (rates[0] && !rates[0].hasOwnProperty('rateGroupId'))) return rates;
    const grouptedRates = groupBy(paginatedRates.fullPagedList, 'rate_group_id');
    return rates.map(rate => {
        return {
            ...rate,
            children: grouptedRates[rate.rateGroupId]
                .map(normalize)
                .sort(sortRates)
                .splice(1),
        };
    });
}

const mapStateProps = createSelector(
    [
        hotelSelector,
        (state, {id}) => getHotelRates(state, id),
        ({hotels: {rates}}, {id}) => rates.paginated[id] || DEFAULT_PAGINATED,
        (state, {id}) => getHotelRatesWarnings(state, id),
        (state, {id}) => getHotelRatesLoading(state, id),
        (state, {id}) => getHotelRatesSelection(state, id),
        ({i18n}) => i18n.currency,
        rateLabeling,
        isTopRatesEnabled,
        (state, {id}) => getRateFilterById(state, id),
        state => getFeatureFlag(state, 'RATE_GROUPING'),
    ],
    (hotel, rates, paged, warnings, loading, selected, currency, labeling, hasTopRates, rateFilter, isRateGrouping) => {
        return {
            providerIds: hotel && hotel['provider_ids'],
            rates: rates && paged.rates ? normalizeRates(rates, paged, isRateGrouping) : null,
            warnings,
            order: paged.order,
            expanded: paged.expanded,
            expandable: paged.expandable,
            ratesLeft: paged.ratesLeft,
            loading,
            labeling: normalize(labeling),
            currency,
            selected,
            rateFilter,
            hasTopRates: hasTopRates,
            isRateGrouping,
            hotel,
        };
    }
);

@connect(mapStateProps, {
    onClose: unselectHotel,
    getRates,
    filterRates,
    setRateOrder,
    expandRates,
    onSelect: selectRate,
    onUnselect: unselectRate,
    onWrongButton: (hotelId, rateKey) => showModal('wrongBedType', {hotelId, rateKey}),
})
export default class RateList extends React.Component {
    static propTypes = {
        hasTopRates: PropTypes.bool,
        id: PropTypes.number.isRequired,
        providerIds: PropTypes.object,
        loading: PropTypes.bool,
        currency: PropTypes.string.isRequired,
        selected: PropTypes.shape({
            rateKey: PropTypes.string,
            booktype: PropTypes.string,
        }),
        order: PropTypes.string.isRequired,
        expanded: PropTypes.string.isRequired,
        expandable: PropTypes.bool.isRequired,
        rates: PropTypes.array,
        warnings: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            })
        ),
        ratesLeft: PropTypes.number,
        getRates: PropTypes.func.isRequired,
        setRateOrder: PropTypes.func.isRequired,
        expandRates: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onUnselect: PropTypes.func.isRequired,
        filterRates: PropTypes.func,
        labeling: PropTypes.object.isRequired,
        rateFilter: PropTypes.array,
        isRateGrouping: PropTypes.bool,
        searchPosition: PropTypes.number,
        onWrongButton: PropTypes.func.isRequired,
        hotel: PropTypes.object,
        asTraveler: PropTypes.bool,
    };
    componentDidMount() {
        const {id, loading, rates, getRates} = this.props;
        !rates && !loading && getRates(id);
    }

    componentDidUpdate({currency: prevCurrency}) {
        const {id, currency, getRates, loading} = this.props;
        if (!loading && currency !== prevCurrency) {
            getRates(id);
        }
    }
    clearFilters = event => {
        event && event.preventDefault();
        const {filterRates, id} = this.props;
        filterRates(id, {});
    };

    handleToggle = () => {
        const {id, expandRates, expanded} = this.props;
        expandRates(id, expanded === 'all' ? 'initial' : 'all');
    };

    handleSort = event => {
        event && event.preventDefault();
        const {order} = this.props;
        const next = order.startsWith('-') ? 'average' : '-average';

        const {id, setRateOrder} = this.props;
        setRateOrder(id, next);
    };

    handleUnselect = () => {
        const {id, onUnselect} = this.props;
        onUnselect(id);
    };

    renderLoading() {
        const {loading} = this.props;
        return loading ? (
            <div className="h-rate__preloader text-center">
                <span className="fade-in-out">{gettext('Loading…')}</span>
            </div>
        ) : null;
    }

    renderRates() {
        const {id, labeling, rates, providerIds, isRateGrouping, searchPosition, onWrongButton, hotel} = this.props;
        if (!rates) {
            return null;
        }

        return rates.map((rate, index) => (
            <React.Fragment key={rate.rateKey}>
                <Rate
                    key={rate.rateKey}
                    hotelId={id}
                    providerId={providerIds && providerIds[rate.provider]}
                    labeling={labeling}
                    searchPosition={searchPosition}
                    ratesLength={rates.length}
                    ratePosition={index + 1}
                    {...rate}
                    onSelect={this.props.onSelect}
                    onWrongButton={onWrongButton}
                    hotel={hotel}
                    originalRate={rate}
                />
                {isRateGrouping && (
                    <SimilarRates searchPosition={searchPosition} rate={rate} hotel={hotel} {...this.props} />
                )}
            </React.Fragment>
        ));
    }

    renderNotification() {
        const {rateFilter} = this.props;
        const showNotification = rateFilter && rateFilter.includes('non_refundable');
        if (!showNotification) {
            return null;
        }

        return (
            <div className="h-rates-alert">
                <p className="h-rates-alert__text">
                    {gettext(
                        'Exclude Non-Refundable rates filter applied. This may exclude some rates where the cancellation policy is ambiguous or not available.'
                    )}
                </p>
            </div>
        );
    }

    renderSorters() {
        const {order} = this.props;
        const priceClassName = classnames(
            'h-rates-sorting__item h-rates-sorting__item--price h-rates-sorting__item--sortable',
            {
                asc: order.startsWith('-'),
                desc: !order.startsWith('-'),
            }
        );

        return (
            <div className="h-rates-sorting">
                <div className={priceClassName} onClick={this.handleSort}>
                    <span className="h-rates-sorting__text">{gettext('Price')}</span>
                </div>

                <div className="h-rates-sorting__item h-rates-sorting__item--description">
                    <span className="h-rates-sorting__text">{gettext('Description')}</span>
                </div>

                <div className="h-rates-sorting__item h-rates-sorting__item--details">
                    <span className="h-rates-sorting__text">{gettext('Details')}</span>
                </div>
            </div>
        );
    }

    renderShowMore() {
        const {expanded, ratesLeft} = this.props;
        const toggleText =
            expanded === 'all'
                ? gettext('Show fewer rates')
                : gettext('Show {count} more rates', {count: ratesLeft});

        return (
            <div className="hotel-tab__footer">
                <Button bsStyle="link" onClick={this.handleToggle}>
                    <strong>{toggleText}</strong>
                </Button>
            </div>
        );
    }

    renderShowOther() {
        return (
            <div className="hotel-tab__footer">
                <Button bsStyle="link" onClick={this.handleToggle}>
                    <strong>{gettext('Show other rates')}</strong>
                </Button>
            </div>
        );
    }

    renderModal() {
        const {id, currency, selected} = this.props;
        if (!selected) {
            return null;
        }
        return <RateDetails hotelId={id} {...selected} currency={currency} onHide={this.handleUnselect} />;
    }

    renderTop() {
        const {rates} = this.props;
        return rates && rates.length ? this.renderShowOther() : null;
    }

    renderFull() {
        const {loading, rates, id, expandable, rateFilter, warnings, hasTopRates} = this.props;
        return (
            <div>
                <HotelTabHeader noButton={true} id={id} key={id} title={<TabLabel total={rates && rates.length} />}>
                    {hasTopRates && <RateSorters id={id} />}
                    <Highlight id="filter-rates" fancy placement="left">
                        <RateFiltersMenu id={id} />
                    </Highlight>
                </HotelTabHeader>
                <RateFiltersTags id={id} />
                {rates && rates.length === 0 && !loading ? (
                    <NoRates
                        clearFilters={this.clearFilters}
                        isFilterIssue={Boolean(rateFilter.length)}
                        warnings={warnings}
                    />
                ) : (
                    <div>
                        {this.renderNotification()}
                        {this.renderSorters()}
                        {this.renderLoading()}
                        {this.renderRates()}
                        {expandable && this.renderShowMore()}
                    </div>
                )}
            </div>
        );
    }
    render() {
        const {id, expanded, hasTopRates, asTraveler, searchPosition, hotel} = this.props;
        return (
            <HotelTab id={id} title={null} testId={hotelsTestIds.results.hotelsTabsContentRates}>
                {hasTopRates && (
                    <TopRates
                        hotelId={id}
                        asTraveler={asTraveler}
                        searchPosition={searchPosition}
                        originalHotel={hotel}
                    />
                )}

                {expanded === 'initial' && hasTopRates ? this.renderTop() : this.renderFull()}

                {this.renderModal()}
            </HotelTab>
        );
    }
}
