import gettext from 'airborne/gettext';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {HotelTabHeader} from './HotelTab';
import NoRates from './NoRates';
import Highlight from 'airborne/homepage2/Highlight';
import BookButton, {BOOK_ATTR_VALUES} from 'airborne/search2/MultiBookButton';

import normalize from 'airborne/search2/helpers/normalize';
import {hotelLabeling, rateLabeling} from 'airborne/search2/helpers/hyatt';
import {
    getHotelRates, getHotelRatesLoading, getHotelRatesWarnings,
    displayRoomType, displayBedTypes,
} from 'airborne/search2/helpers/rates';
import {format as formatPrice} from 'airborne/utils/price';

import {addOffer, removeOffer} from 'airborne/store/modules/search_hotels/actions/offers';
import {selectRate} from 'airborne/store/modules/search_hotels/actions/rates';
import PernightTooltip from 'airborne/search2/hotel/RatePernightTooltip';
import OutOfPolicy from 'airborne/search2/hotel/OutOfPolicy';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {copyHotelOfferInfo} from 'airborne/search2/helpers/copyToClipboard';
import {inEmailOffers, isEnabledEmailOffers} from 'airborne/search2/helpers/offers';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';
import CopyToClipboardTooltip from 'airborne/search2/hotel/CopyToClipboardTooltip';
import AmenitiesIcons from 'midoffice/components/AmenitiesIcons';
import Glyphicons from 'midoffice/components/Glyphicons';
import CancelInfo from './CancelInfo';
import Commissionable from './Commissionable';
import PaidPolicy from './PaidPolicy';
import PaymentIcons from './PaymentIcons';
import ProviderLabel from './ProviderLabel';
import RateCode from './RateCode';


function tagBy(list, fn) {
    return list.reduce((acc, datum)=> {
        return fn(datum).reduce((acc, tag)=> {
            return {...acc, [tag]: datum};
        }, acc);
    }, {});
}

function findBest(list, bestRate) {
    return list.reduce(({found, list}, rate)=> ({
        found: found || rate === bestRate,
        list: [...list, [rate, !found && rate === bestRate]],
    }), {found: false, list: []}).list;
}

const RateShape = {
    rateKey: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    average: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    rateCode: PropTypes.string,
    rateCompanyName: PropTypes.string,
    roomTypeCode: PropTypes.string,
    clientPreferred: PropTypes.bool,
    membersOnly: PropTypes.bool,
    tmcPreferred: PropTypes.bool,
    rateValueAdds: PropTypes.array.isRequired,
    paidPolicy: PropTypes.string.isRequired,
    commission: PropTypes.shape({
        commissionable: PropTypes.bool,
    }).isRequired,
    paymentOptions: PropTypes.array.isRequired,
    cvvRequired: PropTypes.bool.isRequired,
    cancelBy: PropTypes.string,
    cancellationPolicy: PropTypes.string,
    cancellationPolicyKnown: PropTypes.bool,
    nonRefundable: PropTypes.bool,
    qualities: PropTypes.arrayOf(PropTypes.string),
    rateDescription: PropTypes.string,
    ratePromoline: PropTypes.string,
};

class RateHeader extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        highlighted: PropTypes.bool,
        rate: PropTypes.object,
    };

    getSecondPartTitle(rate, title, cnrTitle) {
        return (rate && rate.clientPreferred) ? cnrTitle : title;
    }
    renderTitle(index, rate) {
        return [
            this.getSecondPartTitle(rate, gettext('Flexible Rate without Loyalty Points'), gettext('Flexible Rate without Loyalty Points / Client Rate')),
            this.getSecondPartTitle(rate, gettext('Flexible Rate with Loyalty Points'), gettext('Flexible Rate with Loyalty Points / Client Rate')),
            gettext('Lowest Rate'),
        ][index];
    }

    render() {
        const {index, highlighted, rate} = this.props;
        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            {highlighted &&
                <Highlight id="smart-choices-table" fancy placement="top" >
                    <div className="best-rates__label">
                        {'Smart Choice'}
                    </div>
                </Highlight>}
            <div className="best-rates__title">
                {this.renderTitle(index, rate)}
            </div>
        </td>
        );
    }
}

class EmailOfferBtn extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        offered: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    handleClick = ()=> {
        const {hotelId, rateKey, onClick} = this.props;
        onClick(hotelId, rateKey);
    };

    render() {
        const {offered} = this.props;
        return (
            <Button
                size="sm"
                variant="link"
                className={classnames('no-margin', {'highlight-orange': offered})}
                style={{whiteSpace: 'pre-line'}}
                onClick={this.handleClick}
            >
                {offered
                    ? gettext('Remove from Email Offers')
                    : gettext('Add to Email Offers')
                }
            </Button>
        );
    }
}

const BOOK_TYPES = [
    BOOK_ATTR_VALUES.best_non_loyalty_rate,
    BOOK_ATTR_VALUES.best_loyalty_rate,
    BOOK_ATTR_VALUES.cheapest
];

function getBookType(index, highlighted) {
    return classnames(BOOK_TYPES[index], {
        [BOOK_ATTR_VALUES.best_overall_rate]: highlighted,
    });
}

const BOOK_TYPES_FOR_ATTRS = [
    'data-sc-best-non-loyalty',
    'data-sc-best-loyalty',
    'data-sc-cheapest',
];

function getBookTypeAttributes(index, isBestOveral) {
    return {
        'data-sc-best-loyalty': false,
        'data-sc-best-rate': isBestOveral,
        'data-sc-best-non-loyalty': false,
        'data-sc-cheapest': false,
        'data-non-sc': false,
        'data-book-button': true,
        [BOOK_TYPES_FOR_ATTRS[index]]: true,
    };
}

export function getMarkOfInterestLabel({membersOnly = false, isCorporate = false, isDiscounted = false}, isRateList = false) {
    const memberLabel = isRateList ? gettext('Members Only') : gettext('Member Rate');

    if (membersOnly && isCorporate && isDiscounted) {
        return memberLabel;
    }

    if (isCorporate && isDiscounted) {
        return gettext('Business Rate');
    }

    if (membersOnly) {
        return memberLabel;
    }

    if (isCorporate) {
        return gettext('Business Rate');
    }

    if (isDiscounted) {
        return gettext('Discounted Rate');
    }

    return '';

};

function getLabels(isOnRequest) {
    return isOnRequest
        ? {
            single: gettext('Request'),
            multi:  gettext('Request For'),
            remaining: gettext('Request this rate for all travelers »'),
        }
        : {
            single: gettext('Book'),
            multi: gettext('Book For'),
            remaining: gettext('Book this rate for all travelers »'),
        };
}

@connect(function (state, {hotelId, rate}) {
    const canOffer = isEnabledEmailOffers(state, 0);
    return {
        offered: canOffer && inEmailOffers(state, {hotelId, rateKey: rate && rate.rateKey}),
        canOffer,
    };
}, {addOffer, removeOffer})
class PriceRow extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        offered: PropTypes.bool,
        index: PropTypes.number,
        canOffer: PropTypes.bool,
        highlighted: PropTypes.bool,
        hotelLabeling: PropTypes.object,
        rate: PropTypes.shape(RateShape),
        labeling: PropTypes.shape({
            outOfPolicy: PropTypes.bool,
        }),
        addOffer: PropTypes.func.isRequired,
        removeOffer: PropTypes.func.isRequired,
        searchPosition: PropTypes.number,
        numberOfRates: PropTypes.number,
        originalHotel: PropTypes.object,
    };

    handleCopyToClipboard = () => {
        const {originalHotel, rate} = this.props;
        copyHotelOfferInfo(originalHotel, rate);
    }

    render() {
        const {
            hotelId,
            highlighted,
            offered,
            canOffer,
            index,
            labeling,
            hotelLabeling,
            rate: {
                rateKey,
                currency,
                average,
                outOfPolicy,
                isOnRequest,
                changeDuringStay,
                outOfPolicyReason,
            },
            addOffer,
            removeOffer,
            searchPosition,
            numberOfRates
        } = this.props;
        const [formatCurrency, formatAverage] = formatPrice(average, currency).split(' ');
        const LABELS = getLabels(isOnRequest);
        const showOutOfPolicy = outOfPolicy && labeling.outOfPolicy && hotelLabeling.outOfPolicy;

        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            <div className="best-rates__price">
                <div className="best-rates__price__value">
                    {changeDuringStay && <PernightTooltip hotelId={hotelId} rateKey={rateKey}/>}
                    <sup className="best-rates__price__currency">{formatCurrency}</sup> {formatAverage}
                </div>
                <div className="text-gray small">{gettext('avg. / night')}</div>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <BookButton hotelId={hotelId} rateKey={rateKey}
                        variant={isOnRequest ? 'warning' : 'info'}
                        multiBookLabel={LABELS.multi}
                        data-hotel-position={searchPosition}
                        remainingBookLabel={LABELS.remaining}
                        data-rate-position={highlighted ? null : index + 1}
                        data-number-of-rates={numberOfRates}
                        data-booktype={getBookType(index, highlighted)}
                        {...getBookTypeAttributes(index, highlighted)}
                    >
                        {LABELS.single}
                    </BookButton>

                    <CopyToClipboardTooltip onClick={this.handleCopyToClipboard} className="best-rates__btn-init" />
                </div>

                {canOffer && <EmailOfferBtn hotelId={hotelId} rateKey={rateKey} offered={offered} onClick={offered ? removeOffer : addOffer} />}

                {isOnRequest && (
                    <AnnotateTooltip id="top-rate-on-request-label">
                        <div className="best-rates__aside">
                            <span className="highlight-orange">
                                {gettext('On-Request Rate')} <Glyphicons bsClass="glyphicons" glyph="circle-info"/>
                            </span>
                        </div>
                        {gettext('On-request rates confirmation will not be immediate and may take up to 24 hours.')}
                    </AnnotateTooltip>
                )}

                <div className="best-rates__aside best-rates__aside--danger">
                    <OutOfPolicy show={showOutOfPolicy} reason={outOfPolicyReason} currency={currency} />
                </div>
            </div>
        </td>);
    }
}


class RateCodeRow extends React.Component {
    static title() {
        return gettext('Supplier');
    }

    static propTypes = {
        highlighted: PropTypes.bool,
        rate: PropTypes.shape(RateShape),
        labeling: PropTypes.shape({
            supplier: PropTypes.bool,
            agencyNegotiated: PropTypes.bool,
            clientNegotiated: PropTypes.bool,
        }),
    };

    render() {
        const {highlighted, labeling, rate: {provider, providerId, rateCompanyName, rateCode, roomTypeCode, clientPreferred, tmcPreferred, membersOnly, isDiscounted, isCorporate}} = this.props;
        const rateOfInterestLabel = getMarkOfInterestLabel({membersOnly, isCorporate, isDiscounted});

        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            <ProviderLabel id={providerId} provider={provider} show={labeling.supplier} className="rev-provider-ico" />
            &nbsp;
            <RateCode
                inline
                provider={provider}
                rate={rateCompanyName || rateCode}
                room={roomTypeCode}
                client={clientPreferred && labeling.clientNegotiated}
                tmc={tmcPreferred && labeling.agencyNegotiated}
                membersOnly={membersOnly} />

            {rateOfInterestLabel.length > 0 && <div>
                <span className="best-rates__rate-label">{rateOfInterestLabel}</span>
            </div>}
        </td>);
    }
}

class AmenityRow extends React.Component {
    static title() {
        return gettext('Amenities');
    }

    static propTypes = {
        highlighted: PropTypes.bool,
        rate: PropTypes.shape(RateShape),
    };

    render() {
        const {highlighted, rate: {rateValueAdds}} = this.props;

        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            <AmenitiesIcons withWrapper codes={rateValueAdds} />
        </td>);
    }
}

function join(...args) {
    return args.filter(item => Boolean(item)).join(' ');
}

function RoomTypeLabel({remainingRoomsNumber, roomType}) {
    const remainingLabel = [
        displayRoomType(roomType),
        remainingRoomsNumber > 0 && gettext('{remainingRoomsNumber} left', {remainingRoomsNumber})
    ].filter(item => item);

    return (<div>
        <strong>{gettext('Room:')}</strong> {remainingLabel.join(' • ')}
    </div>);
}

RoomTypeLabel.propTypes = {
    remainingRoomsNumber: PropTypes.number,
    roomType: PropTypes.string,
};

class DetailsRow extends React.Component {
    static title() {
        return gettext('Details');
    }
    static propTypes = {
        asTraveler: PropTypes.bool,
        highlighted: PropTypes.bool,
        hotelId: PropTypes.number.isRequired,
        rate: PropTypes.shape(RateShape),
        onWrongButton: PropTypes.func.isRequired,
    };

    handleWrongButtonClick = () => {
        const {hotelId, rate: {rateKey}, onWrongButton} = this.props;
        onWrongButton(hotelId,rateKey);
    };

    render() {
        const {
            highlighted,
            hotelId,
            asTraveler,
            rate: {
                rateKey,
                paidPolicy,
                commission: {commissionable}, loyaltyCardAllowed,
                bedTypes,
                remainingRoomsNumber,
                roomType,
            },
        } = this.props;
        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            <div className="h-rate__details">
                <PaidPolicy
                    hotelId={hotelId}
                    rateKey={rateKey}
                    paidPolicy={paidPolicy} />

                {!asTraveler && (<div>
                    <Commissionable value={commissionable} />
                </div>)}

                <div className="best-rates__info">
                    <div className="pull-left">
                        <RoomTypeLabel remainingRoomsNumber={remainingRoomsNumber} roomType={roomType} />
                        <div>
                            <strong>{gettext('Bed:')}</strong> {displayBedTypes(bedTypes)}
                        </div>
                    </div>

                    <div className="pull-right">
                        <Button className="btn-link--narrow btn-xs text-xs" variant="link"
                            onClick={this.handleWrongButtonClick}>{gettext('Improve')}</Button>
                    </div>
                </div>

                {loyaltyCardAllowed && <div className="best-rates__info text-xs highlight-green">
                    <strong>{gettext('Loyalty point eligible')}</strong>
                </div>}
            </div>
        </td>);
    }
}

// RoomInfo is displayed like part of DetailsRow on the UI. But because of FF flexbox
// bugs the only way to make proper layout is to render RoomInfo as seperate <TR>
class RoomInfoRow extends React.Component {
    static propTypes = {
        highlighted: PropTypes.bool,
        rate: PropTypes.shape(RateShape),
    };
    static hideEmpty = true;

    render() {
        const {highlighted, rate: {ratePromoline, rateDescription}} = this.props;
        const className = classnames(
            'best-rates--unstyled',
            {'best-rates--highlighted': highlighted}
        );
        return (<td className={className}>
            <div className="h-rate__details">
                <div className="best-rates__info">
                    <AnnotateTooltip id="company_preferred_tier">
                        <Button className="btn-link--narrow btn-xs" variant="link">
                            {gettext('Room Information')} <Glyphicons bsClass="glyphicon" glyph="info-sign" />
                        </Button>
                        {join(ratePromoline, rateDescription)}
                    </AnnotateTooltip>
                </div>
            </div>
        </td>);
    }
}

class PaymentRow extends React.Component {
    static title() {
        return gettext('Accepted Payments');
    };

    static propTypes = {
        highlighted: PropTypes.bool,
        rate: PropTypes.shape(RateShape),
    };

    render() {
        const {highlighted, rate: {paymentOptions, cvvRequired}} = this.props;

        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            <PaymentIcons plain paymentOptions={paymentOptions} cvv={cvvRequired} />

        </td>);
    }

}

class PolicyRow extends React.Component {
    static title() {
        return gettext('Cancellation Policy');
    };

    static propTypes = {
        highlighted: PropTypes.bool,
        index: PropTypes.number.isRequired,
        hotelId: PropTypes.number.isRequired,
        rate: PropTypes.shape(RateShape),
        onSelect: PropTypes.func.isRequired,
    };

    handleDetails = (event) => {
        event && event.preventDefault();
        const {booktype} = event.target.dataset;
        const {hotelId, rate: {rateKey}} = this.props;
        this.props.onSelect(hotelId, rateKey, booktype);
    }

    render() {
        const {highlighted, index, hotelId, rate: {rateKey, nonRefundable, cancelBy, cancellationPolicy, cancellationPolicyKnown}} = this.props;

        return (<td className={classnames({'best-rates--highlighted': highlighted})}>
            <CancelInfo
                hotelId={hotelId}
                rateKey={rateKey}
                nonRefundable={nonRefundable}
                cancelBy={cancelBy}
                cancelPolicy={cancellationPolicy}
                cancelPolicyKnown={cancellationPolicyKnown} />

            <div className="best-rates__details">
                <Button size="sm" variant="link" className="btn-link--narrow" onClick={this.handleDetails} data-booktype={getBookType(index, highlighted)}>
                    {gettext('Rate Details »')}
                </Button>
            </div>
        </td>);
    }

}

class LoaderRow extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };

    render() {
        return (<td className="best-rates--empty">
            <div className="best-rates__loader">
                <div className="loading-spinner loading-spinner--dark"/>
                <div className="best-rates__loader__text fade-in-out">
                    {this.props.children}
                </div>
            </div>
        </td>);
    }
}

class EmptyRow extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        hideBorder: PropTypes.bool,
    };

    render() {
        const className = classnames(
            'best-rates--empty',
            {'best-rates--unstyled': this.props.hideBorder}
        );

        return (<td className={className}>
            <div className="best-rates__empty-label">
                {this.props.children}
            </div>
        </td>);
    }
}

@connect(createSelector(
    (state, {hotelId})=> getHotelRates(state, hotelId),
    (state, {hotelId})=> getHotelRatesLoading(state, hotelId),
    (state, {hotelId})=> getHotelRatesWarnings(state, hotelId),
    rateLabeling,
    hotelLabeling,
    (rates, loading, warnings, labeling, hotelLabeling)=> ({
        rates: normalize(rates),
        labeling: normalize(labeling),
        hotelLabeling: normalize(hotelLabeling),
        loading,
        warnings,
    }),
), {
    onSelect: selectRate,
    onWrongButton: (hotelId, rateKey) => showModal('wrongBedType', {hotelId, rateKey}),
})
export default class TopRates extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        asTraveler: PropTypes.bool,
        hotelLabeling: PropTypes.object,
        hotelId: PropTypes.number.isRequired,
        labeling: PropTypes.shape({
            supplier: PropTypes.bool.isRequired,
            agencyNegotiated: PropTypes.bool.isRequired,
            clientNegotiated: PropTypes.bool.isRequired,
            outOfPolicy: PropTypes.bool.isRequired,
        }),
        rates: PropTypes.arrayOf(PropTypes.shape(RateShape)),
        warnings: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
            })
        ),
        onSelect: PropTypes.func.isRequired,
        searchPosition: PropTypes.number,
        onWrongButton: PropTypes.func.isRequired,
        originalHotel: PropTypes.object,
    };

    render() {
        const {loading, labeling, hotelLabeling, hotelId, asTraveler, rates, warnings, onSelect, searchPosition, onWrongButton} = this.props;
        if (rates && rates.length === 0 && !loading) {
            return (<NoRates warnings={warnings} asTraveler={asTraveler} />);
        }

        const tagged = tagBy(rates || [], (rate)=> (rate.qualities || []));
        const toprates = findBest([
            tagged['best_non_loyalty_rate'] || null,
            tagged['best_loyalty_rate'] || null,
            tagged['cheapest'] || null,
        ], tagged['best_overall_rate'] || {});

        const ratesLength = toprates.filter(([rate]) => Boolean(rate)).length;

        const rowComponents = [
            RateHeader,
            PriceRow, RateCodeRow, AmenityRow,
            DetailsRow, RoomInfoRow, PaymentRow, PolicyRow,
        ];

        return (<div>
            <HotelTabHeader
                id={hotelId} />

            <div className="hotel-tab__best-rates" data-testid="best-rates">
                <table className="table best-rates">
                    <tbody>
                        {rowComponents.map((Row, rowIdx)=> (<tr key={rowIdx}>
                            <th className={Row.hideEmpty && 'best-rates--unstyled'}>
                                {Row.title && Row.title()}
                            </th>

                            {toprates.map(([rate, isBest], idx)=>
                                (rate || Row === RateHeader)
                                    ? <Row
                                        key={idx}
                                        index={idx}
                                        rate={rate}
                                        highlighted={isBest}
                                        asTraveler={asTraveler}
                                        searchPosition={searchPosition}
                                        numberOfRates={ratesLength}
                                        hotelId={hotelId}
                                        labeling={labeling}
                                        hotelLabeling={hotelLabeling}
                                        onSelect={onSelect}
                                        onWrongButton={onWrongButton}
                                        originalHotel={this.props.originalHotel}
                                    />
                                    : ((loading && rowIdx === 1)
                                        ? <LoaderRow key={idx}>{gettext('Searching, please wait…')}</LoaderRow>
                                        : <EmptyRow key={idx} hideBorder={Row.hideEmpty}>
                                            {(rowIdx === 1) ? gettext('N/A') : '–'}
                                        </EmptyRow>)
                            )}
                        </tr>))}
                    </tbody>
                </table>

                <footer className="best-rates__footer">
                    <em>
                        * {gettext('A rate may appear on the table twice if it fits into more than one category.')}
                    </em>
                </footer>
            </div>
        </div>);
    }
}
